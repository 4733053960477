<template>---------
  <el-table-v2
      :columns="columns"
      :data="data"
      :width="700"
      :height="400"
  />
  <p>{{coll}}</p>
  <p>{{roww}}</p>
  <p>---</p>
</template>

<script setup>
import {computed, ref} from 'vue'

const columns = ref([
  {
    "key": "column-0",
    "dataKey": "column-0",
    "title": "Column 0"
  },
  {
    "key": "column-1",
    "dataKey": "column-1",
    "title": "Column 1"
  }
])

const data = ref([
  {
    "id": "row-0",
    "parentId": null,
    "column-0": "Row 0 - Col 0",
    "column-1": "Row 0 - Col 1"
  },
  {
    "id": "row-1",
    "parentId": null,
    "column-0": "Row 1 - Col 0",
    "column-1": "Row 1 - Col 1"
  }
])


  const columnsstat = computed(() => [
  {
    label: 'sdfsf',
    field: 'receipt_all_receipt_show',
  }
])
</script>
